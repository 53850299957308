export default {
  numberConditions: [
    ['未満', 'less_than'],
    ['と等しい', 'eq'],
    ['と等しくない', 'not_eq'],
    ['以下', 'not_greater_than'],
    ['以上', 'not_less_than'],
    ['より大きい', 'greater_than'],
  ],
  stringConditions: [
    ['と等しい', 'eq'],
    ['と等しくない', 'not_eq'],
    ['を含む', 'include'],
    ['を含まない', 'not_include'],
    ['と正規表現がマッチ', 'regex'],
  ],
  popupPositions: [
    ['中央にモーダル表示', 'modal'],
    ['画面下に表示', 'bottom'],
  ],
  defaultPageEvent: process.env.REACT_APP_ENV === 'development'
    ? '{"ruleGroups":{"chainType":"waitAll","rules":[{"chainType":"any","rules":[{"resourceId":0,"condition":"less_than","parameter":[0]}]}]}}'
    : '{"ruleGroups":{"chainType":"waitAll","rules":[{"chainType":"any","rules":[{"resourceId":1,"condition":"include","parameter":["https://xxx.com/cart"]}]},{"chainType":"any","rules":[{"resourceId":12,"condition":"not_less_than","parameter":[1]},{"resourceId":13,"condition":"eq","parameter":[20]}]},{"chainType":"any","rules":[{"resourceId":16,"condition":"not_less_than","parameter":[1]}]}]}}',
  defaultCenterPopupContent: {
    htmlBody: `<div class="rtg-modal">
    <!-- ポップアップ非表示ボタンサンプル -->
    <!-- class名navi-popup-closeを付与した要素をクリックした場合、ポップアップを非表示にします -->
    <div class="navi-popup-close">
        <span class="close close-message-box" title="Close">×</span>
    </div>

    <div class="rtg-modal-logo">
        <h2 class="logo">
            <!-- ロゴ画像 -->
            <img src="https://s3-ap-northeast-1.amazonaws.com/rtg-mail/modal_template/img/logo.png">
        </h2>
    </div>

    <!-- カートの中身を残しますか？ -->
    <div class="rtg-modal-title">
        <h3 class="txt">カートの中身を残しますか？</h3>
    </div>

    <div class="rtg-modal-body">
        <div class="form">
            <p class="medium">会員登録すると商品を<br class="br-pc">お気に入り登録して後で確認できます。</p>
            <!-- 「会員登録する」ボタン -->
            <a href="http://example.jp">
                <p class="txtbtn">会員登録する</p>
            </a>
        </div>
        <div>
            <img src="https://s3-ap-northeast-1.amazonaws.com/rtg-mail/modal_template/img/or01.png"
                 style="width: 100%;">
        </div>
        <div class="form">
            <p class="medium">お急ぎの方は、<br class="br-pc">メールアドレスだけご入力ください。</p>
            <!-- class名navi-popup-next-page-buttonを付与した要素をクリックした場合、2ページヘのポップアップに遷移します。すでに2ページ目の場合は、何も起こりません。 -->
            <!-- 「メールで送る」ボタン -->
            <p class="navi-popup-next-page-button txtbtn">メールで送る</p>
        </div>

        <!-- ポップアップ非表示ボタンサンプル -->
        <!-- class名navi-popup-closeを付与した要素をクリックした場合、ポップアップを非表示にします -->
        <div class="navi-popup-close box-container">
            <p class="box"><a href='#'>会員ログイン</a></p>
            <p class="closetext box">いいえ、終了します。</p>
        </div>

    </div>

    <!-- ポップアップ遷移(前ページ)ボタンサンプル -->
    <!-- class名navi-popup-prev-page-buttonを付与した要素をクリックした場合、
       1ページヘのポップアップに遷移します。
       すでに1ページ目の場合は、何も起こりません。 -->
    <!-- <a class="navi-popup-prev-page-button"></a> -->
    <!-- ポップアップ遷移(次ページ)ボタンサンプル -->
    <!-- class名navi-popup-next-page-buttonを付与した要素をクリックした場合、
       2ページヘのポップアップに遷移します。
       すでに2ページ目の場合は、何も起こりません。 -->
    <!-- <a class="navi-popup-next-page-button"></a> -->
</div>`,
    style: `/* ### クラス名 pop_a モーダル部分のレイアウト ### */
/* ページ共通*/
.navi-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    display: block;
    background-color: rgba(10, 10, 10, 0.45098);
    line-height: 1.3;
    overflow: scroll;
}
.navi-popup {
    margin-left:0px;
    margin-right:0px;
    margin: auto;
    z-index: 1000;
    height: 100%;
}
.navi-popup-outer {
    display: table;
    height:auto;
    position: relative;
    width: 100%;
    height: 100%;
}
.navi-popup-inner {
    margin: auto;
    width: auto;
    height: auto;
    position: relative;
    display: inline-block;
}

.navi-popup-cell {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.navi-popup-content {
    min-width: 200px;
    min-height: 200px;
}

.rtg-modal {
    background-color: #fff;
    background-repeat: round;
    background-size: cover;
    z-index: 1;
    max-width: 560px; /*PC用設定*/
    width: 92%; /*スマホ用設定*/
    height: 100%;
    position: relative;
    margin: 30px 6px 10px 6px;
    border-radius: 6px;
    border: 2px solid #c1c1c1;
    box-shadow: 0 0 5px #444;
    min-height: 200px;
    -webkit-animation: popin 0.3s;
    animation: popin 0.3s;
    font-family: sans-serif;
}
.rtg-modal .close-message-box {
    background-color: rgba(0,0,0,0.7);
    border-radius: 50%;
    /*border: 1px solid #fff;*/
    /*box-shadow: 0px 0px 5px #444;*/
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 25px;
    font-weight: bold;
    height: 28px;
    width: 28px;
    line-height: 23px;
    text-align: center;
    /*text-shadow: 0px 0px 5px #fff;*/
    position: absolute;
    top: -20px;
    margin: 5px;
    right: -20px;
    z-index: 2;
}
/* ロゴ */
.rtg-modal .rtg-modal-logo {
    font-size: 17px;
    background-color: #f3f3f3;
    border-radius: 6px 6px 0 0;
    /*background: url(img/main3.jpg) center center no-repeat;*/
    background-size: cover;
    color: #000;
    padding: 10px 0 10px 6px;
    border-bottom: 2px solid #ddd;
    text-align: center;
}
.rtg-modal .rtg-modal-logo h2.logo {
    height: auto;
    text-align: center;
    margin: 0 auto;
}
.rtg-modal .rtg-modal-logo h2.logo img {
    max-width:100px;
    min-width:200px;
    min-height:auto;
    margin: 0 auto;
    vertical-align: middle;
}
/* ポップアップタイトル */
.rtg-modal .rtg-modal-title {
    color: #fff;
    padding: 0 10px 0 10px;
    border-radius: 4px 4px 0 0;
    text-align: center;
}
.rtg-modal .rtg-modal-title h3.txt {
    color: #333333;
    font-size: 1.5em;
    font-weight: bold;
}
.rtg-modal .rtg-modal-title h3.img {
    height: auto;
    text-align: center;
    margin: 0 auto;
}
.rtg-modal .rtg-modal-title h3.img img {
    width:80%;
    min-height:auto;
    vertical-align: middle;
    margin: auto;
    padding: 30px 10px 20px 10px;
}
.rtg-modal .rtg-modal-body {
    padding: 0px 20px;
    font-size: 1em;
}
.rtg-modal .box-container {
  display: flex;
  justify-content: center;
}
.rtg-modal .box-container .box {
  padding: 0 1rem;
}
.rtg-modal .box-container .box a {
  font-size: 0.7em;
}
/* ページ共通 P*/
.rtg-modal p {
    color: #676767;
    line-height: 1.2em;
}
.rtg-modal .rtg-modal-body p.large {
    font-size: 1.0em;
    font-weight: bold;
    text-align: center;
    margin: 6px auto;
}
.rtg-modal .rtg-modal-body p.medium {
    font-size: 0.9em;
    font-weight: bold;
    text-align: center;
    margin: 0 auto;
}
.rtg-modal .rtg-modal-body p.small {
    font-size: 0.8em;
    font-weight: normal;
    text-align: center;
}
.rtg-modal .rtg-modal-body p.closetext {
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    opacity: 0.8;
    padding-top: 0;
    font-size: 0.7em;
    text-decoration: underline;
    margin: 20px 0;
}

/* ページ１ ############# */
.rtg-modal .rtg-modal-body .form,
.rtg-modal .rtg-modal-body form {
    margin: 20px auto;
    text-align: center;
}

/* ページ１ 会員登録する、メールで送る、イメージ画像ボタン ################################# */
/* ページ１ 会員登録する、メールで送る、イメージ画像ボタン 会員登録する 画像指定 */
.rtg-modal .rtg-modal-body .form p.imgbtn01 {
    background: url(https://s3-ap-northeast-1.amazonaws.com/rtg-mail/modal_template/img/1_btn_01.png) center center no-repeat;
    background-position: 0 0;
    background-size:cover;
}
/* ページ１ 会員登録する、メールで送る、イメージ画像ボタン メールで送る 画像指定 */
.rtg-modal .rtg-modal-body .form p.imgbtn02 {
    background: url(https://s3-ap-northeast-1.amazonaws.com/rtg-mail/modal_template/img/1_btn_02.png) center center no-repeat;
    background-position: 0 0;
    background-size:cover;
}
/* ページ２ メモを送るイメージ画像ボタン ### */
.rtg-modal form input[name=imgbtn]{
    background: url(https://s3-ap-northeast-1.amazonaws.com/rtg-mail/modal_template/img/2_btn_01.png) center center no-repeat;
    background-position: 0 0;
    background-size:cover;
}
/* ページ１、２ 会員登録する、メールで送る、メモを送るイメージ画像ボタン 基本hover */
.rtg-modal .rtg-modal-body .form p.imgbtn01:hover,
.rtg-modal .rtg-modal-body .form p.imgbtn02:hover,
.rtg-modal form input:hover[name=imgbtn] {
    background-position: 0 -50px;
    background-size:cover;
}
/* ページ１ 会員登録する、メールで送る、イメージ画像ボタン 基本 */
.rtg-modal .rtg-modal-body .form p.imgbtn,
.rtg-modal form input[name=imgbtn],
.rtg-modal form input:hover[name=imgbtn] {
    display: inline-block;
    cursor: pointer;
    text-indent:-9999px;
    border: 0;
    width: 180px;
    height: 50px;
    margin: 18px auto;
}

/* ページ２ メールアドレス入力 ### */
.rtg-modal form input[type=email] {
    padding: 10px 20px 13px 20px;
    margin: 10px 0 10px 0;
    font-size: 1.3em;
    width: 60%;
    border-radius: 4px;
    border: 1px solid #ccc;
    -webkit-font-smoothing: antialiased;
}
/* ページ１、２ メモを送るテキストボタン ### */
.rtg-modal form input[name=txtbtn],
.rtg-modal .rtg-modal-body p.txtbtn {
    display: inline-block;
    text-transform: uppercase;
    text-align:center;
    font-weight: bold;
    padding: 12px 26px 12px 26px;
    background-color: #275fb7;
    text-decoration: none;
    font-size: 1.3em;
    line-height: 1.2em;
    border-radius: 4px;
    color: #fff;
    box-shadow: 0px 0px 10px rgba(255, 255, 255, 1);
    border: 1px solid #f5f5f5;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;
    -webkit-appearance: none;
}
.rtg-modal form input:hover[name=txtbtn],
.rtg-modal .rtg-modal-body p.txtbtn:hover {
    background-color: #568ecf;
    cursor: pointer;
}


@media screen and (min-width: 560px){
  .br-pc { display:block; }
  .br-sp { display:none; }
}
@media screen and (max-width: 560px){
  .br-pc { display:none; }
  .br-sp { display:block; }
}

@-webkit-keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-ms-keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-webkit-keyframes popin {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }
    85% {
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}
@-ms-keyframes popin {
    0% {
        -ms-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }
    85% {
        -ms-transform: scale(1.05);
        transform: scale(1.05);
        opacity: 1;
    }
    100% {
        -ms-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}
@keyframes popin {
    0% {
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }
    85% {
        -webkit-transform: scale(1.05);
        -ms-transform: scale(1.05);
        transform: scale(1.05);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}`,
  },
  defaultBottomPopupContent: {
    htmlBody: `<div class="navi-popup">
    <div class="naviplus-bottom-outer">
        <div id="naviplus-modal-inner">
            <figure>
                <div class="naviplus-shadow-pc">
                    <a href="http://www.naviplus.co.jp/rtg-mail/" target="_blank" style="cursor: pointer;" >
                        <img src="https://s3-ap-northeast-1.amazonaws.com/rtg-mail/bottom-img/tomiz_pc_L_bnr1.png">
                    </a>
                    <a href="http://www.naviplus.co.jp/" target="_blank" style="cursor: pointer;">
                        <span class="close-message-box" title="Close"></span>
                    </a>
                </div>
            </figure>
        </div>
    </div>
</div>`,
    style: `/* ### クラス名 pop_a モーダル部分のレイアウト ### */
/* ページ共通*/
@CHARSET "utf-8";
.naviplus-shadow-sp img {
    width: 90%;
    /*-webkit-filter: drop-shadow(0 0 5px #444);*/
}
.naviplus-shadow-pc img {
    max-width: 720px;
    width: 90%;
    /*-webkit-filter: drop-shadow(0 0 5px #444);*/
}

.naviplus-shadow-sp,
.naviplus-shadow-pc {
    /*max-width: 720px;*/
    /*width: 90%;*/
    /*margin: auto;*/
    -webkit-filter: drop-shadow(0 0 5px #444);
    transition-property: initial;
    transition-duration: 6s;
    transition-timing-function: initial;
    transition-delay: initial;
}

.naviplus-top-outer {
    text-align: center;
    position: fixed;
    width: 100% !important;
    height: 300px;
    top: 0;
}

.naviplus-bottom-outer {
    text-align: center;
    position: fixed;
    width: 100% !important;
    bottom: 0;
}

.naviplus-modal-inner .naviplus-fadeIn {
    animation: fadeIn 3s ease-in-out 0s 5 normal;
    -webkit-animation: fadeIn 3s ease-in-out 0s 5 normal;
}


.naviplus-modal-inner .close-message-box {
    /*background-image: url(https://s3-ap-northeast-1.amazonaws.com/rtg-mail/bottom-img/tomiz_pc_L_bnr1_btn2.png);*/ /*位置確認用*/
    background-repeat: no-repeat;
    background-position: top right;
    background-size: contain;
    color: #000000;
    position: absolute;
    padding: 0 20px 40px 10px;
    margin: 0 0 0 -30px;
}

@media screen and (min-width: 560px){
  .br-pc { display:block; }
  .br-sp { display:none; }
}
@media screen and (max-width: 560px){
  .br-pc { display:none; }
  .br-sp { display:block; }
}


/*下から表示*/
figure {
  animation-duration: 2s;
  animation-name: slidein;
}
@keyframes slidein {
  from {
    margin-bottom: 0%;
    height: 100%;
    opacity:0;
  }
  to {
    margin-top: 100%;
    height: 300%;
    opacity: 1;
  }
}


/*fadein設定
figure {
    animation: fadeIn 1.5s ease 0s 1 normal;
    -webkit-animation: fadeIn 1.5s 0s 1 normal;
}*/
/*popin設定
figure {
    -webkit-animation: popin 0.3s;
    animation: popin 0.3s;
}*/

@-webkit-keyframes fadein {
    20% {opacity: 0;}
    100% {opacity: 1;}
}
@-ms-keyframes fadein {
    20% {opacity: 0;}
    100% {opacity: 1;}
}
@keyframes fadein {
    20% {opacity: 0;}
    100% {opacity: 1;}
}
@-webkit-keyframes popin {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }
    85% {
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}
@-ms-keyframes popin {
    0% {
        -ms-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }
    85% {
        -ms-transform: scale(1.05);
        transform: scale(1.05);
        opacity: 1;
    }
    100% {
        -ms-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}
@keyframes popin {
    0% {
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }
    85% {
        -webkit-transform: scale(1.05);
        -ms-transform: scale(1.05);
        transform: scale(1.05);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}`,
  },
  defaultPopupContentsAttributes: [
    {
      area: 'modal',
      javascript: '',
      htmlBody: `<div class="rtg-modal">
    <!-- ポップアップ非表示ボタンサンプル -->
    <!-- class名navi-popup-closeを付与した要素をクリックした場合、ポップアップを非表示にします -->
    <div class="navi-popup-close">
        <span class="close close-message-box" title="Close">×</span>
    </div>

    <div class="rtg-modal-logo">
        <h2 class="logo">
            <!-- ロゴ画像 -->
            <img src="https://s3-ap-northeast-1.amazonaws.com/rtg-mail/modal_template/img/logo.png">
        </h2>
    </div>

    <!-- カートの中身を残しますか？ -->
    <div class="rtg-modal-title">
        <h3 class="txt">カートの中身を残しますか？</h3>
    </div>

    <div class="rtg-modal-body">
        <div class="form">
            <p class="medium">会員登録すると商品を<br class="br-pc">お気に入り登録して後で確認できます。</p>
            <!-- 「会員登録する」ボタン -->
            <a href="http://example.jp">
                <p class="txtbtn">会員登録する</p>
            </a>
        </div>
        <div>
            <img src="https://s3-ap-northeast-1.amazonaws.com/rtg-mail/modal_template/img/or01.png"
                 style="width: 100%;">
        </div>
        <div class="form">
            <p class="medium">お急ぎの方は、<br class="br-pc">メールアドレスだけご入力ください。</p>
            <!-- class名navi-popup-next-page-buttonを付与した要素をクリックした場合、2ページヘのポップアップに遷移します。すでに2ページ目の場合は、何も起こりません。 -->
            <!-- 「メールで送る」ボタン -->
            <p class="navi-popup-next-page-button txtbtn">メールで送る</p>
        </div>

        <!-- ポップアップ非表示ボタンサンプル -->
        <!-- class名navi-popup-closeを付与した要素をクリックした場合、ポップアップを非表示にします -->
        <div class="navi-popup-close box-container">
            <p class="box"><a href='#'>会員ログイン</a></p>
            <p class="closetext box">いいえ、終了します。</p>
        </div>

    </div>

    <!-- ポップアップ遷移(前ページ)ボタンサンプル -->
    <!-- class名navi-popup-prev-page-buttonを付与した要素をクリックした場合、
       1ページヘのポップアップに遷移します。
       すでに1ページ目の場合は、何も起こりません。 -->
    <!-- <a class="navi-popup-prev-page-button"></a> -->
    <!-- ポップアップ遷移(次ページ)ボタンサンプル -->
    <!-- class名navi-popup-next-page-buttonを付与した要素をクリックした場合、
       2ページヘのポップアップに遷移します。
       すでに2ページ目の場合は、何も起こりません。 -->
    <!-- <a class="navi-popup-next-page-button"></a> -->
</div>`,
      style: `/* ### クラス名 pop_a モーダル部分のレイアウト ### */
/* ページ共通*/
.navi-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    display: block;
    background-color: rgba(10, 10, 10, 0.45098);
    line-height: 1.3;
    overflow: scroll;
}
.navi-popup {
    margin-left:0px;
    margin-right:0px;
    margin: auto;
    z-index: 1000;
    height: 100%;
}
.navi-popup-outer {
    display: table;
    height:auto;
    position: relative;
    width: 100%;
    height: 100%;
}
.navi-popup-inner {
    margin: auto;
    width: auto;
    height: auto;
    position: relative;
    display: inline-block;
}

.navi-popup-cell {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.navi-popup-content {
    min-width: 200px;
    min-height: 200px;
}

.rtg-modal {
    background-color: #fff;
    background-repeat: round;
    background-size: cover;
    z-index: 1;
    max-width: 560px; /*PC用設定*/
    width: 92%; /*スマホ用設定*/
    height: 100%;
    position: relative;
    margin: 30px 6px 10px 6px;
    border-radius: 6px;
    border: 2px solid #c1c1c1;
    box-shadow: 0 0 5px #444;
    min-height: 200px;
    -webkit-animation: popin 0.3s;
    animation: popin 0.3s;
    font-family: sans-serif;
}
.rtg-modal .close-message-box {
    background-color: rgba(0,0,0,0.7);
    border-radius: 50%;
    /*border: 1px solid #fff;*/
    /*box-shadow: 0px 0px 5px #444;*/
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 25px;
    font-weight: bold;
    height: 28px;
    width: 28px;
    line-height: 23px;
    text-align: center;
    /*text-shadow: 0px 0px 5px #fff;*/
    position: absolute;
    top: -20px;
    margin: 5px;
    right: -20px;
    z-index: 2;
}
/* ロゴ */
.rtg-modal .rtg-modal-logo {
    font-size: 17px;
    background-color: #f3f3f3;
    border-radius: 6px 6px 0 0;
    /*background: url(img/main3.jpg) center center no-repeat;*/
    background-size: cover;
    color: #000;
    padding: 10px 0 10px 6px;
    border-bottom: 2px solid #ddd;
    text-align: center;
}
.rtg-modal .rtg-modal-logo h2.logo {
    height: auto;
    text-align: center;
    margin: 0 auto;
}
.rtg-modal .rtg-modal-logo h2.logo img {
    max-width:100px;
    min-width:200px;
    min-height:auto;
    margin: 0 auto;
    vertical-align: middle;
}
/* ポップアップタイトル */
.rtg-modal .rtg-modal-title {
    color: #fff;
    padding: 0 10px 0 10px;
    border-radius: 4px 4px 0 0;
    text-align: center;
}
.rtg-modal .rtg-modal-title h3.txt {
    color: #333333;
    font-size: 1.5em;
    font-weight: bold;
}
.rtg-modal .rtg-modal-title h3.img {
    height: auto;
    text-align: center;
    margin: 0 auto;
}
.rtg-modal .rtg-modal-title h3.img img {
    width:80%;
    min-height:auto;
    vertical-align: middle;
    margin: auto;
    padding: 30px 10px 20px 10px;
}
.rtg-modal .rtg-modal-body {
    padding: 0px 20px;
    font-size: 1em;
}
.rtg-modal .box-container {
  display: flex;
  justify-content: center;
}
.rtg-modal .box-container .box {
  padding: 0 1rem;
}
.rtg-modal .box-container .box a {
  font-size: 0.7em;
}
/* ページ共通 P*/
.rtg-modal p {
    color: #676767;
    line-height: 1.2em;
}
.rtg-modal .rtg-modal-body p.large {
    font-size: 1.0em;
    font-weight: bold;
    text-align: center;
    margin: 6px auto;
}
.rtg-modal .rtg-modal-body p.medium {
    font-size: 0.9em;
    font-weight: bold;
    text-align: center;
    margin: 0 auto;
}
.rtg-modal .rtg-modal-body p.small {
    font-size: 0.8em;
    font-weight: normal;
    text-align: center;
}
.rtg-modal .rtg-modal-body p.closetext {
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    opacity: 0.8;
    padding-top: 0;
    font-size: 0.7em;
    text-decoration: underline;
    margin: 20px 0;
}

/* ページ１ ############# */
.rtg-modal .rtg-modal-body .form,
.rtg-modal .rtg-modal-body form {
    margin: 20px auto;
    text-align: center;
}

/* ページ１ 会員登録する、メールで送る、イメージ画像ボタン ################################# */
/* ページ１ 会員登録する、メールで送る、イメージ画像ボタン 会員登録する 画像指定 */
.rtg-modal .rtg-modal-body .form p.imgbtn01 {
    background: url(https://s3-ap-northeast-1.amazonaws.com/rtg-mail/modal_template/img/1_btn_01.png) center center no-repeat;
    background-position: 0 0;
    background-size:cover;
}
/* ページ１ 会員登録する、メールで送る、イメージ画像ボタン メールで送る 画像指定 */
.rtg-modal .rtg-modal-body .form p.imgbtn02 {
    background: url(https://s3-ap-northeast-1.amazonaws.com/rtg-mail/modal_template/img/1_btn_02.png) center center no-repeat;
    background-position: 0 0;
    background-size:cover;
}
/* ページ２ メモを送るイメージ画像ボタン ### */
.rtg-modal form input[name=imgbtn]{
    background: url(https://s3-ap-northeast-1.amazonaws.com/rtg-mail/modal_template/img/2_btn_01.png) center center no-repeat;
    background-position: 0 0;
    background-size:cover;
}
/* ページ１、２ 会員登録する、メールで送る、メモを送るイメージ画像ボタン 基本hover */
.rtg-modal .rtg-modal-body .form p.imgbtn01:hover,
.rtg-modal .rtg-modal-body .form p.imgbtn02:hover,
.rtg-modal form input:hover[name=imgbtn] {
    background-position: 0 -50px;
    background-size:cover;
}
/* ページ１ 会員登録する、メールで送る、イメージ画像ボタン 基本 */
.rtg-modal .rtg-modal-body .form p.imgbtn,
.rtg-modal form input[name=imgbtn],
.rtg-modal form input:hover[name=imgbtn] {
    display: inline-block;
    cursor: pointer;
    text-indent:-9999px;
    border: 0;
    width: 180px;
    height: 50px;
    margin: 18px auto;
}

/* ページ２ メールアドレス入力 ### */
.rtg-modal form input[type=email] {
    padding: 10px 20px 13px 20px;
    margin: 10px 0 10px 0;
    font-size: 1.3em;
    width: 60%;
    border-radius: 4px;
    border: 1px solid #ccc;
    -webkit-font-smoothing: antialiased;
}
/* ページ１、２ メモを送るテキストボタン ### */
.rtg-modal form input[name=txtbtn],
.rtg-modal .rtg-modal-body p.txtbtn {
    display: inline-block;
    text-transform: uppercase;
    text-align:center;
    font-weight: bold;
    padding: 12px 26px 12px 26px;
    background-color: #275fb7;
    text-decoration: none;
    font-size: 1.3em;
    line-height: 1.2em;
    border-radius: 4px;
    color: #fff;
    box-shadow: 0px 0px 10px rgba(255, 255, 255, 1);
    border: 1px solid #f5f5f5;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;
    -webkit-appearance: none;
}
.rtg-modal form input:hover[name=txtbtn],
.rtg-modal .rtg-modal-body p.txtbtn:hover {
    background-color: #568ecf;
    cursor: pointer;
}


@media screen and (min-width: 560px){
  .br-pc { display:block; }
  .br-sp { display:none; }
}
@media screen and (max-width: 560px){
  .br-pc { display:none; }
  .br-sp { display:block; }
}

@-webkit-keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-ms-keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-webkit-keyframes popin {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }
    85% {
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}
@-ms-keyframes popin {
    0% {
        -ms-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }
    85% {
        -ms-transform: scale(1.05);
        transform: scale(1.05);
        opacity: 1;
    }
    100% {
        -ms-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}
@keyframes popin {
    0% {
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }
    85% {
        -webkit-transform: scale(1.05);
        -ms-transform: scale(1.05);
        transform: scale(1.05);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}`,
    },
    {
      area: 'modal',
      javascript: '',
      htmlBody: `<div class="rtg-modal">
    <!-- ポップアップ非表示ボタンサンプル -->
    <!-- class名navi-popup-closeを付与した要素をクリックした場合、ポップアップを非表示にします -->
    <div class="navi-popup-close">
        <span class="close close-message-box" title="Close">×</span>
    </div>

    <div class="rtg-modal-logo">
        <h2 class="logo">
            <!-- ロゴ画像 -->
            <img src="https://s3-ap-northeast-1.amazonaws.com/rtg-mail/modal_template/img/logo.png">
        </h2>
    </div>

    <!-- メールアドレスをご入力ください。 -->
    <div class="rtg-modal-title">
        <h3 class="txt">メールアドレスを<br class="br-sp">ご入力ください。</h3>
        <!-- <h3 class="img"><img src="https://s3-ap-northeast-1.amazonaws.com/rtg-mail/modal_template/img/2_h3.png"></h3> -->
    </div>

    <div class="rtg-modal-body">
        <p class="small">※弊社はメールアドレスをメモを送る目的のみで<br>利用し、メール配信後の保有は致しません。</p>

        <!-- メール送信用FORMタグのサンプル -->
        <!-- FORMにはnavi-popup-prospects-formのclassを付与する必要があります。 -->
        <form class="navi-popup-prospects-form">
            <!-- prospectsEmailのname属性を設定されたinput要素のvalue値が、獲得emailとして扱われます。 -->
            <input type="email" name="prospectsEmail" placeholder="your-email@exmaple.com">
            <!-- 「メモを送る」ボタン -->
            <input type="submit" name="txtbtn" value="メモを送る">
            <!-- <input type="submit" name="imgbtn" value="メモを送る"> -->
        </form>

    </div>
    <div>
        <img src="https://s3-ap-northeast-1.amazonaws.com/rtg-mail/modal_template/img/sp01.png" style="width: 100%;height: 0px;">
    </div>
</div>`,
      style: `/* ### クラス名 pop_a モーダル部分のレイアウト ### */
/* ページ共通*/
.navi-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    display: block;
    background-color: rgba(10, 10, 10, 0.45098);
    line-height: 1.3;
    overflow: scroll;
}
.navi-popup {
    margin-left:0px;
    margin-right:0px;
    margin: auto;
    z-index: 1000;
    height: 100%;
}
.navi-popup-outer {
    display: table;
    height:auto;
    position: relative;
    width: 100%;
    height: 100%;
}
.navi-popup-inner {
    margin: auto;
    width: auto;
    height: auto;
    position: relative;
    display: inline-block;
}

.navi-popup-cell {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.navi-popup-content {
    min-width: 200px;
    min-height: 200px;
}

.rtg-modal {
    background-color: #fff;
    background-repeat: round;
    background-size: cover;
    z-index: 1;
    max-width: 560px; /*PC用設定*/
    width: 92%; /*スマホ用設定*/
    height: 100%;
    position: relative;
    margin: 30px 6px 10px 6px;
    border-radius: 6px;
    border: 2px solid #c1c1c1;
    box-shadow: 0 0 5px #444;
    min-height: 200px;
    -webkit-animation: popin 0.3s;
    animation: popin 0.3s;
    font-family: sans-serif;
}
.rtg-modal .close-message-box {
    background-color: rgba(0,0,0,0.7);
    border-radius: 50%;
    /*border: 1px solid #fff;*/
    /*box-shadow: 0px 0px 5px #444;*/
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 25px;
    font-weight: bold;
    height: 28px;
    width: 28px;
    line-height: 23px;
    text-align: center;
    /*text-shadow: 0px 0px 5px #fff;*/
    position: absolute;
    top: -20px;
    margin: 5px;
    right: -20px;
    z-index: 2;
}
/* ロゴ */
.rtg-modal .rtg-modal-logo {
    font-size: 17px;
    background-color: #f3f3f3;
    border-radius: 6px 6px 0 0;
    /*background: url(img/main3.jpg) center center no-repeat;*/
    background-size: cover;
    color: #000;
    padding: 10px 0 10px 6px;
    border-bottom: 2px solid #ddd;
    text-align: center;
}
.rtg-modal .rtg-modal-logo h2.logo {
    height: auto;
    text-align: center;
    margin: 0 auto;
}
.rtg-modal .rtg-modal-logo h2.logo img {
    max-width:100px;
    min-width:200px;
    min-height:auto;
    margin: 0 auto;
    vertical-align: middle;
}
/* ポップアップタイトル */
.rtg-modal .rtg-modal-title {
    color: #fff;
    padding: 0 10px 0 10px;
    border-radius: 4px 4px 0 0;
    text-align: center;
}
.rtg-modal .rtg-modal-title h3.txt {
    color: #333333;
    font-size: 1.5em;
    font-weight: bold;
}
.rtg-modal .rtg-modal-title h3.img {
    height: auto;
    text-align: center;
    margin: 0 auto;
}
.rtg-modal .rtg-modal-title h3.img img {
    width:80%;
    min-height:auto;
    vertical-align: middle;
    margin: auto;
    padding: 30px 10px 20px 10px;
}
.rtg-modal .rtg-modal-body {
    padding: 0px 20px;
    font-size: 1em;
}
/* ページ共通 P*/
.rtg-modal p {
    color: #676767;
    line-height: 1.2em;
}
.rtg-modal .rtg-modal-body p.large {
    font-size: 1.0em;
    font-weight: bold;
    text-align: center;
    margin: 6px auto;
}
.rtg-modal .rtg-modal-body p.medium {
    font-size: 0.9em;
    font-weight: bold;
    text-align: center;
    margin: 0 auto;
}
.rtg-modal .rtg-modal-body p.small {
    font-size: 0.8em;
    font-weight: normal;
    text-align: center;
}
.rtg-modal .rtg-modal-body p.closetext {
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    opacity: 0.8;
    padding-top: 0;
    font-size: 0.7em;
    text-decoration: underline;
    margin: 20px 0;
}

/* ページ１ ############# */
.rtg-modal .rtg-modal-body .form,
.rtg-modal .rtg-modal-body form {
    margin: 20px auto;
    text-align: center;
}

/* ページ１ 会員登録する、メールで送る、イメージ画像ボタン ################################# */
/* ページ１ 会員登録する、メールで送る、イメージ画像ボタン 会員登録する 画像指定 */
.rtg-modal .rtg-modal-body .form p.imgbtn01 {
    background: url(https://s3-ap-northeast-1.amazonaws.com/rtg-mail/modal_template/img/1_btn_01.png) center center no-repeat;
    background-position: 0 0;
    background-size:cover;
}
/* ページ１ 会員登録する、メールで送る、イメージ画像ボタン メールで送る 画像指定 */
.rtg-modal .rtg-modal-body .form p.imgbtn02 {
    background: url(https://s3-ap-northeast-1.amazonaws.com/rtg-mail/modal_template/img/1_btn_02.png) center center no-repeat;
    background-position: 0 0;
    background-size:cover;
}
/* ページ２ メモを送るイメージ画像ボタン ### */
.rtg-modal form input[name=imgbtn]{
    background: url(https://s3-ap-northeast-1.amazonaws.com/rtg-mail/modal_template/img/2_btn_01.png) center center no-repeat;
    background-position: 0 0;
    background-size:cover;
}
/* ページ１、２ 会員登録する、メールで送る、メモを送るイメージ画像ボタン 基本hover */
.rtg-modal .rtg-modal-body .form p.imgbtn01:hover,
.rtg-modal .rtg-modal-body .form p.imgbtn02:hover,
.rtg-modal form input:hover[name=imgbtn] {
    background-position: 0 -50px;
    background-size:cover;
}
/* ページ１ 会員登録する、メールで送る、イメージ画像ボタン 基本 */
.rtg-modal .rtg-modal-body .form p.imgbtn,
.rtg-modal form input[name=imgbtn],
.rtg-modal form input:hover[name=imgbtn] {
    display: inline-block;
    cursor: pointer;
    text-indent:-9999px;
    border: 0;
    width: 180px;
    height: 50px;
    margin: 18px auto;
}

/* ページ２ メールアドレス入力 ### */
.rtg-modal form input[type=email] {
    padding: 10px 20px 13px 20px;
    margin: 10px 0 10px 0;
    font-size: 1.3em;
    width: 60%;
    border-radius: 4px;
    border: 1px solid #ccc;
    -webkit-font-smoothing: antialiased;
}
/* ページ１、２ メモを送るテキストボタン ### */
.rtg-modal form input[name=txtbtn],
.rtg-modal .rtg-modal-body p.txtbtn {
    display: inline-block;
    text-transform: uppercase;
    text-align:center;
    font-weight: bold;
    padding: 12px 26px 12px 26px;
    background-color: #275fb7;
    text-decoration: none;
    font-size: 1.3em;
    line-height: 1.2em;
    border-radius: 4px;
    color: #fff;
    box-shadow: 0px 0px 10px rgba(255, 255, 255, 1);
    border: 1px solid #f5f5f5;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;
    -webkit-appearance: none;
}
.rtg-modal form input:hover[name=txtbtn],
.rtg-modal .rtg-modal-body p.txtbtn:hover {
    background-color: #568ecf;
    cursor: pointer;
}


@media screen and (min-width: 560px){
  .br-pc { display:block; }
  .br-sp { display:none; }
}
@media screen and (max-width: 560px){
  .br-pc { display:none; }
  .br-sp { display:block; }
}

@-webkit-keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-ms-keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-webkit-keyframes popin {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }
    85% {
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}
@-ms-keyframes popin {
    0% {
        -ms-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }
    85% {
        -ms-transform: scale(1.05);
        transform: scale(1.05);
        opacity: 1;
    }
    100% {
        -ms-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}
@keyframes popin {
    0% {
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }
    85% {
        -webkit-transform: scale(1.05);
        -ms-transform: scale(1.05);
        transform: scale(1.05);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}`,
    },
    {
      area: 'modal',
      javascript: '',
      htmlBody: `<div class="rtg-modal">
    <!-- ポップアップ非表示ボタンサンプル -->
    <!-- class名navi-popup-closeを付与した要素をクリックした場合、ポップアップを非表示にします -->
    <div class="navi-popup-close">
        <span class="close close-message-box" title="Close">×</span>
    </div>

    <div class="rtg-modal-logo">
        <h2 class="logo">
            <!-- ロゴ画像 -->
            <img src="https://s3-ap-northeast-1.amazonaws.com/rtg-mail/modal_template/img/logo.png">
        </h2>
    </div>

    <!-- メールアドレスをご入力ください。 -->
    <div class="rtg-modal-title">
        <h3 class="txt">ご利用ありがとう<br class="br-sp">ございました！</h3>
        <!-- <h3 class="img"><img src="https://s3-ap-northeast-1.amazonaws.com/rtg-mail/modal_template/img/3_h3.png"></h3> -->
    </div>

    <div class="rtg-modal-body">
        <p class="medium">頂いたメールアドレス宛にメモを送りました。<br class="br-pc">またのお越しをお待ちしております。</p>

        <!-- ポップアップ非表示ボタンサンプル -->
        <!-- class名navi-popup-closeを付与した要素をクリックした場合、ポップアップを非表示にします -->
        <div class="navi-popup-close">
            <p class="closetext">× 閉じる</p>
        </div>
    </div>
    <div>
        <img src="https://s3-ap-northeast-1.amazonaws.com/rtg-mail/modal_template/img/sp01.png" style="width: 100%;height: 0px;">
    </div>
</div>`,
      style: `/* ### クラス名 pop_a モーダル部分のレイアウト ### */
/* ページ共通*/
.navi-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    display: block;
    background-color: rgba(10, 10, 10, 0.45098);
    line-height: 1.3;
    overflow: scroll;
}
.navi-popup {
    margin-left:0px;
    margin-right:0px;
    margin: auto;
    z-index: 1000;
    height: 100%;
}
.navi-popup-outer {
    display: table;
    height:auto;
    position: relative;
    width: 100%;
    height: 100%;
}
.navi-popup-inner {
    margin: auto;
    width: auto;
    height: auto;
    position: relative;
    display: inline-block;
}

.navi-popup-cell {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.navi-popup-content {
    min-width: 200px;
    min-height: 200px;
}

.rtg-modal {
    background-color: #fff;
    background-repeat: round;
    background-size: cover;
    z-index: 1;
    max-width: 560px; /*PC用設定*/
    width: 92%; /*スマホ用設定*/
    height: 100%;
    position: relative;
    margin: 30px 6px 10px 6px;
    border-radius: 6px;
    border: 2px solid #c1c1c1;
    box-shadow: 0 0 5px #444;
    min-height: 200px;
    -webkit-animation: popin 0.3s;
    animation: popin 0.3s;
    font-family: sans-serif;
}
.rtg-modal .close-message-box {
    background-color: rgba(0,0,0,0.7);
    border-radius: 50%;
    /*border: 1px solid #fff;*/
    /*box-shadow: 0px 0px 5px #444;*/
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 25px;
    font-weight: bold;
    height: 28px;
    width: 28px;
    line-height: 23px;
    text-align: center;
    /*text-shadow: 0px 0px 5px #fff;*/
    position: absolute;
    top: -20px;
    margin: 5px;
    right: -20px;
    z-index: 2;
}
/* ロゴ */
.rtg-modal .rtg-modal-logo {
    font-size: 17px;
    background-color: #f3f3f3;
    border-radius: 6px 6px 0 0;
    /*background: url(img/main3.jpg) center center no-repeat;*/
    background-size: cover;
    color: #000;
    padding: 10px 0 10px 6px;
    border-bottom: 2px solid #ddd;
    text-align: center;
}
.rtg-modal .rtg-modal-logo h2.logo {
    height: auto;
    text-align: center;
    margin: 0 auto;
}
.rtg-modal .rtg-modal-logo h2.logo img {
    max-width:100px;
    min-width:200px;
    min-height:auto;
    margin: 0 auto;
    vertical-align: middle;
}
/* ポップアップタイトル */
.rtg-modal .rtg-modal-title {
    color: #fff;
    padding: 0 10px 0 10px;
    border-radius: 4px 4px 0 0;
    text-align: center;
}
.rtg-modal .rtg-modal-title h3.txt {
    color: #333333;
    font-size: 1.5em;
    font-weight: bold;
}
.rtg-modal .rtg-modal-title h3.img {
    height: auto;
    text-align: center;
    margin: 0 auto;
}
.rtg-modal .rtg-modal-title h3.img img {
    width:80%;
    min-height:auto;
    vertical-align: middle;
    margin: auto;
    padding: 30px 10px 20px 10px;
}
.rtg-modal .rtg-modal-body {
    padding: 0px 20px;
    font-size: 1em;
}
/* ページ共通 P*/
.rtg-modal p {
    color: #676767;
    line-height: 1.2em;
}
.rtg-modal .rtg-modal-body p.large {
    font-size: 1.0em;
    font-weight: bold;
    text-align: center;
    margin: 6px auto;
}
.rtg-modal .rtg-modal-body p.medium {
    font-size: 0.9em;
    font-weight: bold;
    text-align: center;
    margin: 0 auto;
}
.rtg-modal .rtg-modal-body p.small {
    font-size: 0.8em;
    font-weight: normal;
    text-align: center;
}
.rtg-modal .rtg-modal-body p.closetext {
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    opacity: 0.8;
    padding-top: 0;
    font-size: 0.7em;
    text-decoration: underline;
    margin: 20px 0;
}

/* ページ１ ############# */
.rtg-modal .rtg-modal-body .form,
.rtg-modal .rtg-modal-body form {
    margin: 20px auto;
    text-align: center;
}

/* ページ１ 会員登録する、メールで送る、イメージ画像ボタン ################################# */
/* ページ１ 会員登録する、メールで送る、イメージ画像ボタン 会員登録する 画像指定 */
.rtg-modal .rtg-modal-body .form p.imgbtn01 {
    background: url(https://s3-ap-northeast-1.amazonaws.com/rtg-mail/modal_template/img/1_btn_01.png) center center no-repeat;
    background-position: 0 0;
    background-size:cover;
}
/* ページ１ 会員登録する、メールで送る、イメージ画像ボタン メールで送る 画像指定 */
.rtg-modal .rtg-modal-body .form p.imgbtn02 {
    background: url(https://s3-ap-northeast-1.amazonaws.com/rtg-mail/modal_template/img/1_btn_02.png) center center no-repeat;
    background-position: 0 0;
    background-size:cover;
}
/* ページ２ メモを送るイメージ画像ボタン ### */
.rtg-modal form input[name=imgbtn]{
    background: url(https://s3-ap-northeast-1.amazonaws.com/rtg-mail/modal_template/img/2_btn_01.png) center center no-repeat;
    background-position: 0 0;
    background-size:cover;
}
/* ページ１、２ 会員登録する、メールで送る、メモを送るイメージ画像ボタン 基本hover */
.rtg-modal .rtg-modal-body .form p.imgbtn01:hover,
.rtg-modal .rtg-modal-body .form p.imgbtn02:hover,
.rtg-modal form input:hover[name=imgbtn] {
    background-position: 0 -50px;
    background-size:cover;
}
/* ページ１ 会員登録する、メールで送る、イメージ画像ボタン 基本 */
.rtg-modal .rtg-modal-body .form p.imgbtn,
.rtg-modal form input[name=imgbtn],
.rtg-modal form input:hover[name=imgbtn] {
    display: inline-block;
    cursor: pointer;
    text-indent:-9999px;
    border: 0;
    width: 180px;
    height: 50px;
    margin: 18px auto;
}

/* ページ２ メールアドレス入力 ### */
.rtg-modal form input[type=email] {
    padding: 10px 20px 13px 20px;
    margin: 10px 0 10px 0;
    font-size: 1.3em;
    width: 60%;
    border-radius: 4px;
    border: 1px solid #ccc;
    -webkit-font-smoothing: antialiased;
}
/* ページ１、２ メモを送るテキストボタン ### */
.rtg-modal form input[name=txtbtn],
.rtg-modal .rtg-modal-body p.txtbtn {
    display: inline-block;
    text-transform: uppercase;
    text-align:center;
    font-weight: bold;
    padding: 12px 26px 12px 26px;
    background-color: #275fb7;
    text-decoration: none;
    font-size: 1.3em;
    line-height: 1.2em;
    border-radius: 4px;
    color: #fff;
    box-shadow: 0px 0px 10px rgba(255, 255, 255, 1);
    border: 1px solid #f5f5f5;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;
    -webkit-appearance: none;
}
.rtg-modal form input:hover[name=txtbtn],
.rtg-modal .rtg-modal-body p.txtbtn:hover {
    background-color: #568ecf;
    cursor: pointer;
}


@media screen and (min-width: 560px){
  .br-pc { display:block; }
  .br-sp { display:none; }
}
@media screen and (max-width: 560px){
  .br-pc { display:none; }
  .br-sp { display:block; }
}

@-webkit-keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-ms-keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-webkit-keyframes popin {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }
    85% {
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}
@-ms-keyframes popin {
    0% {
        -ms-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }
    85% {
        -ms-transform: scale(1.05);
        transform: scale(1.05);
        opacity: 1;
    }
    100% {
        -ms-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}
@keyframes popin {
    0% {
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }
    85% {
        -webkit-transform: scale(1.05);
        -ms-transform: scale(1.05);
        transform: scale(1.05);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}`,
    },
  ],
}
