import { takeLatest, call, put } from 'redux-saga/effects'
import { string } from 'yup'
import * as api from 'services/api'
import { sendgridAction } from 'actions'
import { toast } from 'react-toastify'

const message = 'メール配信システムのステータスを変更しました'
function* handleAddSuppressions(action) {
  const { payload, error } = yield call(api.createSuppressions, action.payload)
  if (error) {
    toast.error(payload.message)
  } else {
    yield put(sendgridAction.createSuppressionsDone(payload))
    toast.success(message)
  }
}

function* handleRemoveSuppression(action) {
  const { payload, error } = yield call(api.removeSuppression, action.payload)
  if (error) {
    toast.error(payload.message)
  } else {
    toast.success(message)
    yield put(sendgridAction.removeSuppressionDone())
  }
}

function* handleValidateDomainAuthentication(action) {
  let errorObj
  const email = action.payload
  const isEmailValid = string().email().isValidSync(email)
  const notification = { notification: 'ALL_CASE' }
  if (!isEmailValid) {
    errorObj = new Error()
    notification.failMess = 'Reply-toアドレスのフォーマットが正しくありません。'
    yield put(sendgridAction.validateDomainAuthenticationDone(errorObj, notification))
    return
  }

  const domain = email.split('@')[1]
  const { payload, error } = yield call(api.validateDomainAuthentication, domain)
  if (error) {
    errorObj = new Error()
    notification.failMess = 'SPFレコードの確認に失敗しました。'
    yield put(sendgridAction.validateDomainAuthenticationDone(errorObj, notification))
    return
  }

  if (payload['isDomainAuthenticated']) {
    notification.succMess = 'SPFレコードは正常です。'
  } else {
    errorObj = new Error()
    notification.failMess = 'SPFレコードが設定されていません。'
  }
  yield put(sendgridAction.validateDomainAuthenticationDone(errorObj, notification))
}

export const sendgridSaga = [
  takeLatest(sendgridAction.createSuppressions, handleAddSuppressions),
  takeLatest(sendgridAction.removeSuppression, handleRemoveSuppression),
  takeLatest(sendgridAction.validateDomainAuthentication, handleValidateDomainAuthentication),
]
