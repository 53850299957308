import call from 'modules/apiCaller'

/**
 * APIのURL等を定義
 */

const API_ROOT = process.env.REACT_APP_API_ROOT

// 認証
export const fetchLogin = (email, password) => call(`${API_ROOT}/users/sign_in`, 'POST', { user: { email, password } })
export const submitLogout = () => call(`${API_ROOT}/users/sign_out`, 'DELETE')
export const submitSignUp = values => call(`${API_ROOT}/users/sign_up/complete`, 'POST', values)

// カレントユーザ
export const fetchCurrentUser = () => call(`${API_ROOT}/api/current_user`, 'GET')
export const submitUpdateCurrentUser = values => call(`${API_ROOT}/api/current_user`, 'PATCH', { currentUser: values })
export const submitUpdatePassword = values => call(`${API_ROOT}/api/current_user/update_password`, 'PUT', { currentUser: values })

// 登録ユーザ
export const fetchRegisteredUsers = () => call(`${API_ROOT}/api/users`, 'GET')
export const submitDeleteRegisteredUser = values => call(`${API_ROOT}/api/users/${values.id}`, 'DELETE')
export const submitCreateRegisteredUser = values => call(`${API_ROOT}/api/users`, 'POST', { user: values })

// メインユーザ
export const submitUpdateMainUser = values => call(`${API_ROOT}/api/main_user/${values.id}`, 'PATCH')

// レコテンプレート
export const fetchRecoTemplates = () => call(`${API_ROOT}/api/reco_templates`, 'GET')
export const submitCreateRecoTemplate = values => call(`${API_ROOT}/api/reco_templates`, 'POST', { reco_template: values })
export const submitUpdateRecoTemplate = values => call(`${API_ROOT}/api/reco_templates/${values.id}`, 'PATCH', { reco_template: values })

// メアド一意性チェック
export const checkEmailUniqueness = queryObj => call(`${API_ROOT}/users/sign_up/email_uniqueness.json`, 'GET', queryObj, { encode: false })

// 会社情報
export const fetchCompany = () => call(`${API_ROOT}/api/companies`, 'GET')
export const submitUpdateCompany = values => call(`${API_ROOT}/api/companies`, 'PATCH', { company: values })
export const fetchCompanyCampaignTemplateValue = () => call(`${API_ROOT}/api/company_campaign_template_value`, 'GET')
export const submitUpdateCompanyCampaignTemplateValue = values => call(`${API_ROOT}/api/company_campaign_template_value`, 'PATCH', { company_campaign_template_value: values })

// メール送信者情報
export const fetchMailSenderInfo = () => call(`${API_ROOT}/api/mail_sender_infos`, 'GET')
export const submitUpdateMailSenderInfo = values => call(`${API_ROOT}/api/mail_sender_infos`, 'PATCH', { mailSenderInfo: values })

// メール送信者情報
export const fetchLineSetting = () => call(`${API_ROOT}/api/line_setting`, 'GET')
export const submitUpdateLineSetting = values => call(`${API_ROOT}/api/line_setting`, 'PATCH', { lineSetting: values })

// オプトアウトリンク設定
export const fetchSubscriptionTrackingSettings = () => call(`${API_ROOT}/api/subscription_tracking_settings`, 'GET')
export const updateSubscriptionTrackingSettings = values => call(`${API_ROOT}/api/subscription_tracking_settings`, 'PATCH', { subscriptionTrackingSettings: values })

// 配信停止
export const submitUpdateSuppressionSetting = emails => call(`${API_ROOT}/api/suppressions`, 'POST', { email_list: emails })

// キャンペーン
export const fetchAbandonedCampaigns = queryObj => call(`${API_ROOT}/api/abandoned_campaigns`, 'GET', { ...queryObj, type: 'all' })
export const submitUpdateAbandonedCampaign = values => call(`${API_ROOT}/api/abandoned_campaigns/${values.id}`, 'PATCH', { abandonedCampaign: values })
export const submitCreateAbandonedCampaign = values => call(`${API_ROOT}/api/abandoned_campaigns`, 'POST', { abandonedCampaign: values })
export const submitSendAbandonedCampaignTestMail = values => call(`${API_ROOT}/api/abandoned_campaigns/send_test`, 'POST', { abandonedCampaign: values })
export const submitDeleteAbandonedCampaign = values => call(`${API_ROOT}/api/abandoned_campaigns/${values.id}`, 'DELETE')
export const searchAbandonedCampaigns = values => call(`${API_ROOT}/api/abandoned_campaigns/search`, 'POST', { abandonedCampaign: values })
export const completeAiOptimized = values => call(`${API_ROOT}/api/abandoned_campaigns/${values.campaignId}/complete_ai_optimized`, 'PATCH', { schedule: values.schedule })

export const fetchCompletedCampaigns = queryObj => call(`${API_ROOT}/api/completed_campaigns`, 'GET', queryObj)
export const submitUpdateCompletedCampaign = values => call(`${API_ROOT}/api/completed_campaigns/${values.id}`, 'PATCH', { completedCampaign: values })
export const submitCreateCompletedCampaign = values => call(`${API_ROOT}/api/completed_campaigns`, 'POST', { completedCampaign: values })
export const submitSendCompletedCampaignTestMail = values => call(`${API_ROOT}/api/completed_campaigns/send_test`, 'POST', { completedCampaign: values })
export const submitDeleteCompletedCampaign = values => call(`${API_ROOT}/api/completed_campaigns/${values.id}`, 'DELETE')

export const fetchContextualCampaigns = queryObj => call(`${API_ROOT}/api/contextual_campaigns`, 'GET', queryObj)
export const submitUpdateContextualCampaign = values => call(`${API_ROOT}/api/contextual_campaigns/${values.id}`, 'PATCH', { contextualCampaign: values })
export const submitCreateContextualCampaign = values => call(`${API_ROOT}/api/contextual_campaigns`, 'POST', { contextualCampaign: values })
export const submitSendContextualCampaignTestMail = values => call(`${API_ROOT}/api/contextual_campaigns/send_test`, 'POST', { contextualCampaign: values })
export const submitDeleteContextualCampaign = values => call(`${API_ROOT}/api/contextual_campaigns/${values.id}`, 'DELETE')

// メール送信ABテスト
export const fetchMailAbTests = queryObj => call(`${API_ROOT}/api/mail_ab_tests`, 'GET', queryObj)
export const submitUpdateMailAbTest = values => call(`${API_ROOT}/api/mail_ab_tests/${values.id}`, 'PATCH', { mailAbTest: values })
export const submitCreateMailAbTest = values => call(`${API_ROOT}/api/mail_ab_tests`, 'POST', { mailAbTest: values })
export const submitDeleteMailAbTest = values => call(`${API_ROOT}/api/mail_ab_tests/${values.id}`, 'DELETE')

// ABテスト-キャンペーン中間テーブル
export const fetchMailAbTestCampaignMaps = () => call(`${API_ROOT}/api/mail_ab_test_campaign_maps`, 'GET')
export const searchMailAbTestCampaignMaps = mailAbTestId => call(`${API_ROOT}/api/mail_ab_test_campaign_maps/search`, 'GET', { mail_ab_test_id: mailAbTestId })

// キャンペーンテンプレート
export const fetchCampaignTemplates = () => call(`${API_ROOT}/api/campaign_templates`, 'GET')

// キャンペーンコピー
export const fetchCampaignCopy = values => call(`${API_ROOT}/api/campaign_copy`, 'POST', values)

// キャンペーンメールプレビュー
export const submitCreateMailPreview = ({ _type, ...values }) => call(`${API_ROOT}/api/${_type}_campaigns/preview`, 'POST', { [`${_type}Campaign`]: values })

// キャンペーンテンプレートプレビュー
export const submitCreateTemplatePreview = values => call(`${API_ROOT}/api/campaign_templates/preview`, 'POST', { [`campaign_template`]: values })

// 配信制限設定
export const fetchLimitDeliverySetting = () => call(`${API_ROOT}/api/limit_delivery_setting`, 'GET')
export const submitUpdateLimitDeliverySetting = values => call(`${API_ROOT}/api/limit_delivery_setting`, 'PATCH', values)

// ポップアップ
export const fetchPopups = queryObj => call(`${API_ROOT}/api/popups`, 'GET', queryObj)
export const submitUpdatePopup = values => call(`${API_ROOT}/api/popups/${values.id}`, 'PATCH', { popup: values })
export const submitCreatePopup = values => call(`${API_ROOT}/api/popups`, 'POST', { popup: values })

// ページリソース
export const fetchPageResources = queryObj => call(`${API_ROOT}/api/page_resources`, 'GET', queryObj)

// ポップアップ後メール
export const fetchProspectsCampaigns = queryObj => call(`${API_ROOT}/api/prospects_campaigns`, 'GET', queryObj)
export const submitUpdateProspectsCampaign = values => call(`${API_ROOT}/api/prospects_campaigns/${values.id}`, 'PATCH', { prospectsCampaign: values })
export const submitCreateProspectsCampaign = values => call(`${API_ROOT}/api/prospects_campaigns`, 'POST', { prospectsCampaign: values })
export const submitSendProspectsCampaignTestMail = values => call(`${API_ROOT}/api/prospects_campaigns/send_test`, 'POST', { prospectsCampaign: values })

// 管理者権限か
export const fetchAdminPermission = () => call(`${API_ROOT}/api/permission`, 'GET')

// イベント
export const fetchEvents = () => call(`${API_ROOT}/api/events`, 'GET')

// お知らせ
export const fetchNewsReleases = queryObj => call(`${API_ROOT}/api/news_releases`, 'GET', queryObj)

// レポート
export const fetchTransitionReport = queryObj => call(`${API_ROOT}/api/reports`, 'GET', queryObj)

// 行動履歴
export const fetchBehaviorHistories = queryObj => call(`${API_ROOT}/api/behaviors`, 'GET', queryObj)
export const createSuppressions = emails => call(`${API_ROOT}/api/sendgrid/suppressions`, 'POST', { emails })
export const removeSuppression = email => call(`${API_ROOT}/api/sendgrid/suppressions`, 'DELETE', { email })
export const validateDomainAuthentication = domain => call(`${API_ROOT}/api/sendgrid/validate_domain_authentication`, 'POST', { domain })

// ElasticSearchのBehaviorsIndexを検索
export const fetchElasticsearchBehaviors = queryObj => call(`${API_ROOT}/api/elasticsearch_behaviors`, 'GET', queryObj)

// ElasticSearchのCustomersIndexを検索
export const fetchElasticsearchCustomers = queryObj => call(`${API_ROOT}/api/elasticsearch_customers`, 'GET', queryObj)

// ElasticSearchのItemsIndexを検索
export const fetchElasticsearchItems = queryObj => call(`${API_ROOT}/api/elasticsearch_items`, 'GET', queryObj)

// 商品
export const fetchItems = queryObj => call(`${API_ROOT}/api/items`, 'GET', queryObj)
export const submitUpdateItem = values => call(`${API_ROOT}/api/items/${values.id}`, 'PATCH', { item: values })

// customer情報
export const findCustomer = code => call(`${API_ROOT}/api/customers/find`, 'GET', { code })
export const updateCustomer = customer => call(`${API_ROOT}/api/customers/${customer.id}`, 'PUT', customer)
export const activateCustomer = customer => call(`${API_ROOT}/api/customers/${customer.id}/activate`, 'PUT', customer)
export const searchCustomers = queryObj => call(`${API_ROOT}/api/customers/search`, 'GET', queryObj)

// 報告レポート設定
export const fetchReportMailSetting = () => call(`${API_ROOT}/api/report_mail_setting`, 'GET')
export const submitUpdateReportMailSetting = values => call(`${API_ROOT}/api/report_mail_setting`, 'PATCH', values)

// 宛先データ取り込み設定
export const fetchCustomerImportSetting = () => call(`${API_ROOT}/api/customer_import_settings`, 'GET')
export const submitUpdateCustomerImportSetting = values => call(`${API_ROOT}/api/customer_import_settings`, 'PUT', values)

// 商品データ取り込み設定
export const fetchItemImportSetting = () => call(`${API_ROOT}/api/item_import_settings`, 'GET')
export const submitUpdateItemImportSetting = values => call(`${API_ROOT}/api/item_import_settings`, 'PUT', values)

// ファイルアップロードAPI用のAPIトークン
export const fetchApiTokens = () => call(`${API_ROOT}/api/api_tokens`, 'GET')
export const submitCreateApiToken = values => call(`${API_ROOT}/api/api_tokens`, 'POST', { apiToken: values })
export const submitDeleteApiToken = values => call(`${API_ROOT}/api/api_tokens/${values.id}`, 'DELETE')

// 宛先データ取り込み履歴
export const fetchBatchStatusCustomerImports = () => call(`${API_ROOT}/api/batch_status_customer_imports`, 'GET')

// 商品データ取り込み履歴
export const fetchBatchStatusItemImports = () => call(`${API_ROOT}/api/batch_status_item_imports`, 'GET')

// 接続テスト
export const fetchConnectionTest = values => call(`${API_ROOT}/api/batch_status_${values._type}_test_imports/${values.id}`, 'GET')
export const submitCreateConnectionTest = values => call(`${API_ROOT}/api/${values._type}_import_test_settings/connection_test`, 'POST', values)

// トラッキングコード設定
export const fetchTrackingSettings = () => call(`${API_ROOT}/api/tracking_settings`, 'GET')
export const submitUpdateTrackingSetting = values => call(`${API_ROOT}/api/tracking_settings/${values.id}`, 'PATCH', { tracking_setting: values })
export const submitCreateTrackingSetting = values => call(`${API_ROOT}/api/tracking_settings`, 'POST', { tracking_setting: values })

// 行動情報
export const fetchBehaviorSummary = queryObj => call(`${API_ROOT}/api/behavior_summaries`, 'GET', queryObj)

// 操作ログ
export const submitUserAccessTrackings = queryObj => call(`${API_ROOT}/api/user_access_trackings`, 'POST', queryObj)

export const fetchAiOptimizedCampaignEmailTracking = campaign_id => call(`${API_ROOT}/api/ai_optimized_campaign_email_tracking/campaign/${campaign_id}`, 'GET')
