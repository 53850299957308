import { handleActions } from 'redux-actions'
import { sendgridAction as action } from 'actions'

const initial = {
  item: {},
  isFetching: false,
  error: undefined,
}

export const sendgrid = handleActions({
  [action.createSuppressions]: (state) => state,
  [action.createSuppressionsDone]: (state, action) => {
    return {
      ...state,
      item: action.payload,
    }
  },
  [action.removeSuppression]: (state) => state,
  [action.removeSuppressionDone]: (state) => {
    return {
      ...state,
      item: {},
    }
  },
}, initial)
